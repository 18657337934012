<template>
    <div>
        <!-- NavBar -->
        <n-navbar />
        <!-- Main -->
        <main class="px-3 pb-3">
            <!-- Logo -->
            <div class="w-100 text-center">
                <!-- <img src="@/assets/logo.png" width="248" /> -->
            </div>
            <!-- Form -->
            <form class="bg-lightwhite border-radius-8 p-3" style="margin-top: 50%;" @submit.stop.prevent="onSubmit">
                <div class="font-17 font-weight-500 text-dark-warm-grey px-2">會員註冊</div>
                <div class="form mt-3 mb-2">
                    <div class="mb-2">
                        <!-- PhoneInput -->
                        <div class="form-group">
                            <label class="bags bg-transparent">姓名</label>
                            <input v-model="customer.name" class="border-radius-8" placeholder="請輸入姓名" required />
                        </div>
                        <!-- BirthMonthInput -->
                        <div class="form-group mt-2">
                            <label class="bags bg-transparent">生日月份</label>
                            <select v-model="customer.birthMonth" class="birth-month">
                                <option v-for="option in monthOptions" :key="option.value" :value="option.value">{{ option.text }}</option>
                            </select>
                        </div>
                        <!-- GenderInput -->
                        <div class="form-group gender mt-2">
                            <label class="bags bg-transparent">性別</label>
                            <div class="text-center">
                                <div class="ship-methods">
                                    <button @click.prevent="customer.gender = 'M'" :class="{ 'bg-white text-black': customer.gender == 'M' }">男</button>
                                    <button @click.prevent="customer.gender = 'F'" :class="{ 'bg-white text-black': customer.gender == 'F' }">女</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- SubmitButton -->
                <button class="btn primary-btn flex-center mt-3" type="submit" :disabled="validateRegister">
                    <span class="font-19">註冊</span>
                </button>
            </form>
        </main>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { Customer } from '@/models'

export default {
    props: ['uid', 'phone'],

    data() {
        return {
            customer: Customer(),
        }
    },

    mounted() {
        this.customer.id = this.shop.merchantId + this.uid
        this.customer.uid = this.uid
        this.customer.merchantId = this.shop.merchantId
        this.customer.phone = this.phone
    },

    computed: {
        ...mapState({
            shop: (state) => state.shop,
        }),

        monthOptions() {
            let ret = []
            for (let i = 1; i <= 12; i++) {
                ret.push({ value: i, text: `${i}月` })
            }
            return ret
        },

        validateRegister() {
            return !this.customer.name && !this.customer.birthMonth && !this.customer.gender
        },
    },

    methods: {
        async onSubmit() {
            await this.$store.dispatch('createDocument', { col: 'customers', doc: this.customer })
            localStorage.setItem(`${this.shop.merchantId}-customerId`, this.customer.id)
            this.$router.push(`/${this.shop.id}`)
        },
    },
}
</script>

<style lang="scss" scoped>
.birth-month {
    width: 100%;
    color: #000;
    font-size: 1.25rem;
    border: 2px solid #f5f5f5;
    padding: 14.5px 1rem 14.5px 5.75rem;
    font-weight: 700;
    background: #fff;
}

.gender {
    background: #fff;
    border-radius: 8px;
    width: 100%;
    color: #000;
    font-size: 1.25rem;
    border: 2px solid #f5f5f5;
    font-weight: 700;
    -moz-appearance: none;
    appearance: none;
    -webkit-appearance: none;
}
.gender > div {
    padding: 14.5px 1rem 14.5px 5.7rem;
}
.form-group .ship-methods {
    background: #f0f2f3;
    display: inline-block;
    vertical-align: middle;
    padding: 2px;
    border-radius: 12px;
}
.form-group .ship-methods > * {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
}
.form-group .ship-methods button {
    padding-top: 6px;
    padding-bottom: 9px;
    font-size: 17px;
    font-weight: 500;
    border-radius: 10px;
    width: 97px;
    color: #787878;
    cursor: pointer;
}
</style>
